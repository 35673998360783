import { snakeCase } from 'lodash'; // 🤢
import {
  I$WWrapper,
  IControllerConfig,
  IPlatformAPI,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import Api, {
  ApiTypes,
  APP_TOAST_EVENT,
  AppToastTypes,
  Group,
  GroupList,
  isUserSiteAdmin,
} from '@wix/social-groups-api';

import { BaseWidgetController } from '../BaseWidgetController';
import {
  GroupsWidgetActions,
  GroupsWidgetProps,
} from '../../types/groups-list/types';
import { GroupsStorage } from '../../storage/GroupsStorage';
import { UpdateProgress } from '../../ContentEditor/UpdateProgress';
import { getTabForGroup, Tab } from '../group-url';
import { ConsoleLogger } from '../../loggers';
import { COMPONENT_ID } from '../../utils/utils';
import getBaseUrl from '../../utils/baseUrl';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PubSubObserver } from '../../../components/Group/controllers/pubSub/PubSubObserver';
import { PubSubEventTypes } from '../../../components/Group/controllers/pubSub/PubSubEventTypes';
import { BaseControllerContext } from 'common/controllers';
import { ApiDelegate } from 'common/api/services/ApiDelegate';
import { SiteNavigation } from 'common/controllers/site-navigation/SiteNavigation';

const { MEMBERS_WITH_MY_APPROVAL } = ApiTypes.v1.CreateGroupsPolicy;

export abstract class BaseGroupsController<T extends GroupsWidgetProps>
  extends BaseWidgetController<T>
  implements PubSubObserver {
  protected readonly wixCodeApi: IWixAPI;
  protected readonly platformAPIs: IPlatformAPI;
  protected api!: Api;
  protected groupModel!: Group;
  protected groupListModel!: GroupList;
  protected config: IControllerConfig;
  private readonly storage: GroupsStorage;
  private groupsInstanceSettings:
    | ApiTypes.v1.GroupsInstanceSettings
    | undefined;
  protected _api: ApiDelegate;

  constructor(controllerContext: ControllerParams) {
    super(controllerContext);
    const { wixCodeApi, platformAPIs, config } = this.controllerConfig;
    this.platformAPIs = platformAPIs;
    this.wixCodeApi = wixCodeApi;
    this.config = config;
    this.initExternalServices(this.getSiteToken()!);
    this.storage = GroupsStorage.fromControllerConfig(this.controllerConfig);
    this.setSubscriptions();
    this.onUserLogin(async () => {
      this.controllerConfig.setProps({
        updateProgress: UpdateProgress.UPDATING,
      } as T);
      this.initExternalServices(this.getSiteToken() as any);
      await this.setGroups(this.config);
    });
    this._api = ApiDelegate.getInstance(
      new BaseControllerContext(controllerContext),
    );
  }

  updateConfig($w: any, updatedConfig: IControllerConfig) {
    this.config = updatedConfig;
    return this.setGroups(updatedConfig);
  }

  protected async setGroups(config: IControllerConfig) {
    let groups: ApiTypes.v1.GroupResponse[] = [];
    let groupUrls = {};
    try {
      groups = await this.getGroups(config);
      this.cacheGroups(groups);
      groupUrls = await this.getGroupUrls(groups);
    } catch (e) {
      console.log('FAILED to get groups');
      this.errorLogger.log(e);
    }
    this.setState(({
      groups,
      groupUrls,
      updateProgress: UpdateProgress.STALE,
    } as any) as Partial<T>);
  }

  public goToGroup = async (groupId: string) => {
    this.setState(({ navigatingToGroup: groupId } as any) as Partial<T>);
    const navigation = new SiteNavigation(this);
    if (this.isEditorMode()) {
      await navigation.navigateInEditor(groupId);
    } else {
      const groupToNavigate = this.groupListModel?.getGroupById(groupId);
      let tabName = Tab.DISCUSSION;

      if (groupToNavigate) {
        this.cacheGroup(groupToNavigate);
        groupId = groupToNavigate.slug || '';
        tabName = getTabForGroup(groupToNavigate);
      }
      return navigation.goToGroup({
        groupId,
        tabName,
      });
    }
  };

  private async getGroupSectionUrl() {
    const prefix = await this.getGroupPagePrefix();
    const baseUrl = this.wixCodeApi.location.baseUrl;
    return `${baseUrl}${prefix}`;
  }

  private async navigateInEditor(groupId: string) {}

  private cacheGroup(group: ApiTypes.v1.GroupResponse) {
    this.storage.setGroup(group);
  }

  protected cacheGroups(groups: ApiTypes.v1.GroupResponse[]) {
    groups.forEach((g) => this.cacheGroup(g));
  }

  public createGroup = async (
    details: ApiTypes.v1.GroupDetails,
    settings: ApiTypes.v1.GroupSettings,
    image?: File,
  ) => {
    this.setState(({
      isGroupCreating: true,
    } as any) as T);
    try {
      const group = await this.groupModel?.create(
        null,
        details,
        settings,
        image,
      );
      this.setState(({
        groups: [...(this.state.groups || []), group],
      } as any) as T);

      if (group) {
        this.cacheGroup(group);
        group.slug && (await this.goToGroup(group.slug));
      }

      if (
        this.state.createGroupPolicy === MEMBERS_WITH_MY_APPROVAL &&
        !isUserSiteAdmin(this.getCurrentUser())
      ) {
        this.platformAPIs.pubSub.publish(
          APP_TOAST_EVENT,
          {
            type: AppToastTypes.GROUP_CREATED_IN_PENDING_STATE,
            options: {
              'group-name': group?.details?.title,
            },
          },
          false,
        );
        return;
      }

      this.platformAPIs.pubSub.publish(
        APP_TOAST_EVENT,
        {
          type: AppToastTypes.GROUP_CREATED,
          options: {
            'group-name': group?.details?.title,
          },
        },
        false,
      );
    } catch (e) {
      console.error(e);
      this.errorLogger.log(e);
    } finally {
      this.setState(({
        isGroupCreating: false,
      } as any) as T);
    }
  };

  public async getGroupsInstanceSettings(): Promise<ApiTypes.v1.GroupsInstanceSettings> {
    if (!this.groupsInstanceSettings) {
      this.groupsInstanceSettings = await this.fetchGroupsInstanceSettings();
    }
    return this.groupsInstanceSettings;
  }

  private async fetchGroupsInstanceSettings(): Promise<ApiTypes.v1.GroupsInstanceSettings> {
    try {
      const { data } =
        (await this.api?.getGroupInstanceSettings()) || ({} as any);
      return data.settings as any;
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
      return {};
    }
  }

  protected async updateGroupInstanceSettings(
    settings?: ApiTypes.v1.GroupsInstanceSettings,
  ): Promise<ApiTypes.v1.GroupsInstanceSettings> {
    const paths = Object.keys(settings || {}).map((key) => {
      return `settings.${snakeCase(key)}`;
    });
    const requestData: any = {
      fieldMask: {
        paths,
      },
      settings,
    };
    const { data } =
      (await this.api?.updateGroupInstanceSettings(requestData)) || ({} as any);
    this.groupsInstanceSettings = data.settings as any;
    return this.groupsInstanceSettings || {};
  }

  protected abstract getGroups(
    config: IControllerConfig,
  ): Promise<ApiTypes.v1.GroupResponse[]>;

  async pageReady($w?: I$WWrapper, wixAPI?: IWixAPI) {
    // Props for SSR
    this.setState(({ actions: this.getActions() } as any) as T);
    // Props after SSR
    const groupsProps = ({
      navigatingToGroup: null,
      updateProgress: UpdateProgress.STALE,
    } as any) as T;
    if (!this.isEditorMode()) {
      groupsProps.groupSectionUrl = await this.getGroupSectionUrl();
    }
    this.setState(groupsProps);
    return Promise.resolve();
  }

  protected getActions(): GroupsWidgetActions {
    return {
      createGroup: this.createGroup,
      goToGroup: this.goToGroup,
    };
  }

  promptLogin() {
    return super.promptLogin({ modal: true });
  }

  abstract getInitialProps(): Promise<Partial<T>>;

  public withdrawJoinRequest(group: ApiTypes.v1.GroupResponse) {
    // Need this because of refreshing `groupModel` in `this.initExternalServices`
    return this.groupModel?.withdrawJoinRequest(group);
  }

  private async getGroupPagePrefix() {
    if (this.isEditorIframe()) {
      return '/group';
    }
    try {
      // TODO: check this
      const sectionId = COMPONENT_ID.GROUP_PAGE;
      const { relativeUrl } = await this.getSectionUrl(sectionId);
      return relativeUrl;
    } catch (e) {
      console.error('Get Group Page prefix: FAIL');
      this.errorLogger.log(e);
    }

    return '/group';
  }

  private isEditorIframe() {
    return this.isEditorMode() && typeof window !== 'undefined';
  }

  public initExternalServices(instance: string) {
    this.api = new Api(
      instance,
      getBaseUrl(this.wixCodeApi),
      new BaseControllerContext(this.controllerContext),
    );
    this.groupModel = new Group('', this.api, this.platformAPIs);
    this.groupListModel = new GroupList(this.api);
  }

  private fetchGroup(groupId: string): Promise<ApiTypes.v1.GroupResponse> {
    const group = new Group(groupId, this.api, this.platformAPIs);
    return group.fetch();
  }

  async getGroupUrls(
    groups: ApiTypes.v1.GroupResponse[],
  ): Promise<{ [key: string]: string }> {
    if (this.isEditorMode()) {
      // location & site apis don't work in editor! https://wix.slack.com/archives/C01A2MH2ZEC/p1615395074010500
      return {};
    }
    const navigation = new SiteNavigation(this);
    const idUrl = await Promise.all(
      groups.map((group) => {
        const { slug, groupId } = group;
        const tabName = getTabForGroup(group);
        return Promise.all([
          groupId,
          navigation.getGroupUrl({ groupId: slug!, tabName }),
        ]);
      }),
    );

    const urls = Object.fromEntries(idUrl);
    return urls;
  }

  removeSubscriptions(): void {}

  setSubscriptions(): void {
    this.controllerConfig.platformAPIs.pubSub.subscribe(
      PubSubEventTypes.JOIN_GROUP,
      () => {
        return this.setGroups(this.config);
      },
      false,
    );
  }
}
