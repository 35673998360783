import { ControllerParams } from '@wix/yoshi-flow-editor';
import {
  ILocation,
  ISiteApis,
  IUser,
  IWixWindowViewMode,
} from '@wix/native-components-infra/dist/src/types/types';
import { ControllerErrorLogger } from '../loggers/ControllerErrorLogger';
import { Environment } from '../loggers/sentryConfig';
import getBaseUrl from '../utils/baseUrl';
import { IApiContext, IWebStorage } from '@wix/social-groups-api';
import { IControllerContext } from 'common/controllers/IControllerContext';
import { ControllerConfig } from 'common/types/WixCodeApi';

export class BaseControllerContext implements IApiContext, IControllerContext {
  protected readonly controllerConfig!: ControllerParams['controllerConfig'];
  protected readonly flowAPI: ControllerParams['flowAPI'];
  protected currentUser: IUser;
  protected readonly appDefId: string;
  protected errorLogger: ControllerErrorLogger;

  constructor(protected readonly controllerContext: ControllerParams) {
    this.controllerConfig = controllerContext.controllerConfig;
    this.currentUser = this.controllerConfig.wixCodeApi.user.currentUser;
    this.appDefId = this.controllerConfig.appParams.appDefinitionId;
    this.flowAPI = controllerContext.flowAPI;
    this.errorLogger = ControllerErrorLogger.getInstance(
      this.controllerConfig,
      Environment.VIEWER_WORKER,
    );
  }

  getWebStorage(): IWebStorage {
    return this.getControllerConfig().platformAPIs.storage.memory;
  }

  getControllerConfig(): ControllerConfig {
    return this.controllerContext.controllerConfig;
  }

  getEnvironment() {
    return this.controllerContext.flowAPI.environment;
  }

  getCurrentUserId(): string {
    return this.getCurrentUser().id;
  }

  getCurrentUser(): IUser {
    return this.controllerConfig.wixCodeApi.user.currentUser;
  }

  isUserLoggedIn(): boolean {
    return this.getCurrentUser().loggedIn;
  }

  protected isUserAdmin(): boolean {
    return this.getCurrentUser().role === 'Admin';
  }

  getSiteToken() {
    return this.controllerConfig.wixCodeApi.site.getAppToken?.(
      this.getAppDefinitionId(),
    )!;
  }

  getAppDefinitionId() {
    return this.appDefId;
  }

  protected getBaseUrls() {
    return this.controllerConfig.appParams.baseUrls;
  }

  getLocale() {
    return this.controllerConfig.wixCodeApi.window.locale || 'en';
  }

  getSiteLanguage() {
    return this.controllerConfig.wixCodeApi.site.language || 'en';
  }

  protected getApiBaseUrl() {
    return getBaseUrl(this.controllerConfig.wixCodeApi);
  }

  getLocation(): ILocation {
    return this.controllerConfig.wixCodeApi.location;
  }

  getSiteApis(): ISiteApis {
    return this.controllerConfig.wixCodeApi.site;
  }

  protected getViewMode(): IWixWindowViewMode {
    return this.controllerConfig.wixCodeApi.window.viewMode;
  }

  protected onUserLogin(callback: any) {
    this.controllerConfig.wixCodeApi.user.onLogin((user: any) => {
      this.currentUser = user;
      callback(user);
    });
  }

  protected async promptLogin(options: Partial<any> = {}) {
    try {
      await this.controllerConfig.wixCodeApi.user.promptLogin({
        lang: this.controllerConfig.wixCodeApi.site.language,
        ...options,
      });
    } catch (e) {
      console.log('[BaseWidgetController.promptLogin] Error');
      this.errorLogger.log(e);
    }
  }

  protected isEditorMode() {
    const { viewMode } = this.controllerConfig.wixCodeApi.window;
    // TODO: now Preview is OOI. WILL BREAK
    return ['Editor', 'Preview'].includes(viewMode);
  }

  isSSR() {
    return this.controllerContext.flowAPI.environment.isSSR;
  }

  getMetaSiteId(): string {
    return this.controllerConfig.platformAPIs.bi?.metaSiteId || '';
  }

  experimentEnabled(key: string) {
    return this.controllerContext.flowAPI.essentials.experiments.enabled(key);
  }

  getHTTPClient() {
    return this.controllerContext.flowAPI.httpClient;
  }

  getTranslations() {
    return this.controllerContext.flowAPI.translations;
  }
}
